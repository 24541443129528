
import { Link, useLocation } from "react-router-dom";

const ProjectNavLink = (props) => {
    const location = useLocation();
    let isActive = false;
    const pathArray = location.pathname.split("/");
    if((pathArray[pathArray.length - 1] === props.urlValue) || (pathArray[2] === undefined && props.urlValue === "beautiful-ux") || (pathArray[2] === undefined && props.urlValue === "ahfc")) {
        isActive = true;
    }
    return <Link to={props.urlValue} key={props.index} className={isActive ? "selected" : ""}><li>{props.value}</li></Link>
}

export default ProjectNavLink;