import ImageComponent from "./imageComponent";

const ImageSection = (props) => {
    let p_className = "section " + props.className;
    let bodyText = [];
    for(var i = 0; i < (props.text).length; i++) {
        bodyText[i] = (props.text)[i];
    }
    const renderList = bodyText.map((item, index) => 
        <p key={index}>{item}</p>
    );
    return (
    <section className={p_className}>
        {renderList}
        {props.title && <h4>{props.title}</h4>}
        <ImageComponent srcImg={props.sourceImg} altText={props.altText} />
    </section>
    )
}

export default ImageSection;