import { useEffect } from "react";

const UnseenVideo = () =>  {

useEffect(() => {
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script1.src = "https://fast.wistia.com/embed/medias/t0g03s7u6t.jsonp";
    script1.async = true;

    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
}, []);

    return (
        <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
                <div className="wistia_embed wistia_async_t0g03s7u6t videoFoam=true">&nbsp;</div></div></div>
        );
};

export default UnseenVideo;
