
const Telig = () => {
    return <article className="center projectSection">
        <h3>Council Rock Telig Model</h3>
        <div className="outerVideo">
        <div className="video">
            <iframe title="telig" src="https://player.vimeo.com/video/249238168?h=dba143d648&title=0&byline=0&portrait=0" className="innerVideo" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
        </div><script src="https://player.vimeo.com/api/player.js"></script>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <p>I took on a fun project for Council Rock, a company specializing in smart electrical boxes. They didn't have enough employees to work their trade show, so I built them an interactive 3D model that users could engage with to learn about the product.</p>
        <p>This project was quite an experiment for me, because I'd never built an interactive 3D web experience before. I used CAD models of their product, edited and significantly optimized using 3D software. I then used WebGL with the library Three.js to display the models, and HTML canvas to display information about them. </p>
        <p>This project was a fantastic learning experience for me in 3D programming. I'd done some 3D programming previously in OpenGL, but had coded everything including shaders and object loaders from the ground up. That's not very practical for quick, low-budget projects, so the experience applying my knowledge using the Three.js library was great.</p>
        <div><a href="https://www.caseyegilbert.com/councilrock/" rel="noreferrer" target="_blank">View Final Product</a></div>
        <p className="italic  underline small">Note: Designed for a 1280x720 screen. Models are highly intricate and take time to load.</p>
    </article>
}

export default Telig;


