import logo from '../img/cg_logo.png'
import resume from '../documents/resume.pdf'

const Header = (props) => {
    return <header className="app-header">
    <div className="leftNav">
      <a href="./"><img className="logo" src={logo} alt="Logo"/></a>
      <a className="resume" rel="noreferrer" target="_blank" href={resume}>Resume</a>
    </div>
    <h2>
      Casey Elizabeth
    </h2>
  </header>
}

export default Header;
