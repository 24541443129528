import Section from "./section";
import PageTitle from "./pageTitle";
import Diamond from "./diamond";

const itchText = [". . . some type of problem . . . something is off. Something could be better. Somebody has an idea. Somebody is hurting. Something inspires us to solve a problem.", "Something we want to change, something we seek to improve."];
const researchText = [
    "\"Much of the confusion we encounter in problem solving today results from misconceptions about the nature of change in social systems and their environments.\" - John Pourdehnad",
    "We seek to understand the problem we are solving, understand the extent of its impact, and include the full diversity affected by its redesign.",
    "As designers, we are faciliatators of a common shared view. We seek to navigate and compile a diversity of viewpoints in order to understand the system and the ripple effects of the problem.",
];
const problemText = [
    "Ultimately, we design for the problem we identify. We have to understand, we have to be careful, and we have to be certain.",
    "This involves incorporating rigorous ethics. Should we be solving this problem? What will happen if we do? What are the considerations on different groups of people? Who are we designing for, and who else does it impact?"
];
const prototypeText = [
    "\"If, after weighing probabilities, and inventing safeguards, and straining for further information, I find myself unable to come to any fixed conclusion in reference to the future . . . the feeling of anxiety arises. When something happens for which I cannot account, I wonder.\" -Charles Sanders Peirce",
    "Design thinking gives us the freedom and creativity to wonder and to imagine. It allows us to be inspired by everything. It gives us the power to imagine a future that doesn't exist, and the courage to believe we can make it.",
    "However, we must continuously test our ideas even as we generate new ones. When we design policies, initiatives, objects, systems- we need ways to test out our ideas to avoid unleashing the unproven on society."

];
const implementText = [
    "\"Solving our most complex problems will require augmenting design thinking with a systems thinking approach as the basis for action.\" -Rowan Conway",
    "We have narrowed our ideas into a potential solution, but the implementation of that solution is as critical as the solution itself. Designing how a solution lives in the wild is just as important as the design of the solution itself. "
];
const assessText = [
    "We must assess how our solution exists in society, and do an honest evaluation of its impact. Designers generate system-level impacts, but we often fail to anticipate the ripple effects of our solutions.",
    "We should never be afraid to say that a solution is not working. We should never be afraid to go back to the drawing board."
];
const refineText = [
    "Let the cycle repeat.",
    "Design is nomadic, situated in circumstances, and circumstances are continuously changing. There is no such thing as a static solution- and we must always be evaluating."
];

const Philosophy = (props) => {
    return <section className="philosophySection bigSection center">
        <div className="diamondContainer">
        <Diamond className="one"/>
        <Diamond className="two"/>
        <Diamond className="three"/>
        </div>
        <PageTitle value="design philosophy" />
        
        <Section number="1" className="center" value="The Itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="left" value="research" text={researchText} hasQuote={true}/>
        <Section number="3" className="center" value="problem" text={problemText} hasQuote={false}/>
        <Section number="4" className="left" value="prototype" text={prototypeText} hasQuote={true}/>
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={true}/>
        <Section number="6" className="left" value="assess impact" text={assessText} hasQuote={false}/>
        <Section number="7" className="center" value="continuous refinement" text={refineText} hasQuote={false}/>
    </section>
}

export default Philosophy;