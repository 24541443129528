import ImageSection from "../imageSection";
import Eggs from "../../img/TimeOff/one.png";
const Egg = () => {
    return <article className="center projectSection" id="strangledEgg">
        <h3>Strangled Egg</h3>
        <ImageSection text={""} sourceImg={Eggs} altText={"It works!"}/>
        <p>This is an exploration in developing 3D scenes using Cinema4D. For this project, I wanted to develop imagery that appeared peaceful and beautiful, but upon closer inspection portrayed environmental destruction. I wanted to visually call attention to the serious consequences of how we treat our environment that exist right in front of our eyes, but that often don't emotionally register with us.</p>
        <p>Created in Cinema4D and Adobe Photoshop.</p>
    </article>
}

export default Egg;


