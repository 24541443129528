import './scss/styles.js';
import Top from './Components/top';
import Philosophy from './Components/philosophy';
import Projects from './Components/projects';
import Footer from './Components/footer';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Paintings from './Components/otherTypes/paintings.js';
import CCL from './Components/projects/ccl.js';
import SmilesPerHour from './Components/projects/smilesperhour.js';
import ResearchProposal from './Components/projects/researchProposal.js';
import AHFC from './Components/projects/ahfc.js';
import MyAbility from './Components/projects/myability.js';
import MyStory from './Components/myStory.js';
import BeautifulUX from './Components/otherTypes/beautiful.js';
import Telig from './Components/otherTypes/telig.js';
import Egg from './Components/otherTypes/egg.js';
import BlastGuage from './Components/otherTypes/blast-guage.js';
import ISISTwitter from './Components/otherTypes/twitter.js';
import Other from './Components/other.js';

function App() {
  return (
    <div className="App">
      <Top />
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/projects" replace />}
        />
        <Route path="/mystory" element={<MyStory />} />
        <Route path="/projects" element={<Projects />}>
          <Route path="" element={<AHFC />} />
          <Route path="CCL" element={<CCL />} />
          <Route path="myability" element={<MyAbility />} />
          <Route path="ahfc" element={<AHFC />} />
          <Route path="smiles" element={<SmilesPerHour />} />
          <Route path="research" element={<ResearchProposal />} />
        </Route>
        <Route path="/philosophy" element={<Philosophy />} />
        <Route path="/other" element={<Other />}>
          <Route path="" element={<BeautifulUX />} />
          <Route path="beautiful-ux" element={<BeautifulUX />} />
          <Route path="telig" element={<Telig />} />
          <Route path="strangled-egg" element={<Egg />} />
          <Route path="blast-guage-system" element={<BlastGuage />} />
          <Route path="isis-twitter-network" element={<ISISTwitter />} />
          <Route path="paintings" element={<Paintings />} />
        </Route>
        <Route path="*" element={<Projects />}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
