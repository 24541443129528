import PageTitle from "./pageTitle";
import Diamond from "./diamond";
import ProjectNav from "./projectNav";
import { Outlet } from "react-router-dom";

const Projects = (props) => {

    const projectNames = [
        "AHFC",
        "CCL Wisconsin",
        "Smiles Per Hour",
        "#MyAbility",
        "Research Proposal"
    ]

    const URLs = [
        "ahfc",
        "ccl",    
        "smiles",
        "myability",
        "research"
    ]

    return <section className="bigSection center">
    <div className="diamondContainer">
    <Diamond className="one"/>
    <Diamond className="two"/>
    <Diamond className="three"/>
    </div>
    <PageTitle value="projects" />
    <ProjectNav itemList={projectNames} urlList={URLs}/>
    <Outlet />
</section>
}

export default Projects;