import UnseenVideo from "../../Videos/unseenVideo";

const BlastGuage = () => {
    return <article className="center">
        <h3>Black Box Biometrics - Blast Gauge System</h3>
        <UnseenVideo />
        <p>Black Box Biometrics, or B3, is a small company that develops guages to measure blast pressure that military members and law enforcement personnel are exposed to. I created the above animation to demonstrate how this technology could benefit the military in giving commanders realtime feedback about the status of their Soldiers. As an Army officer, the need for this technology resonated deeply with me.</p>
        <p>Their product was complex to use, and B3 needed a simple way to teach it to users. I also developed a series of tutorials to explain the purpose behind the product as well as how to use it. These tutorials can be viewed on the <a className="regLink" rel="noreferrer" target="_blank" href="https://blastgauge.com/tutorials">blast gauge website</a>.</p>

    </article>
}

export default BlastGuage;


