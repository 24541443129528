import Header from './header';
import LinkButton from './linkButton'

const Top = (props) => {
    return <section className="top">
        <Header />
        <div className='top-diagonal'>
            <h1 className='titleone'>creator</h1>
            <h1 className='titletwo'>builder <span className='socialchange'><span className='little'>for a better</span> <span className='social'>society</span></span></h1>
            <h1 className='titlethree'>designer</h1>
        </div>
        <div className='bottomButtons'>
        <LinkButton linkTo="/mystory" buttonNumber={0} value='my story'></LinkButton>
        <LinkButton linkTo="/projects" buttonNumber={0} value='projects'></LinkButton>
        <LinkButton linkTo="/philosophy" buttonNumber={1} value='philosophy'></LinkButton>
        <LinkButton className="other" linkTo="/other" buttonNumber={2} value='other . . .'></LinkButton>
        </div>
        </section>
}

export default Top;