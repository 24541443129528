const BeautifulUX = () => {
    return <article className="center overflow">
        <h3>What makes a beautiful experience?</h3>
        <div className="outerVideo">
        <div className="video">
            <iframe  title="Beautiful Design" src="https://player.vimeo.com/video/264151059?h=b711564f2b&color=E1EDF1&title=0&byline=0&portrait=0" className="innerVideo" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        <p >I was posed the question: what makes a beautiful digital experience? I decided to use animation and visuals to show my ideas. After giving it thought, I decided there were three main components to a beautiful digital experience: </p>
        <ul>
            <li><span className="strong">Solves a problem:</span> If the experience is not solving problem, why are we creating? The 'why' behind any experience is critical. As designers, we leave too much impact on the world not to consider carefully why we create the things we create.</li>
            <li><span className="strong">Helps people:</span> Although my thoughts on this have evolved to focus on life and society (including plants and animals) the basic concept remains the same. The net impact must be positive. We must be careful not to allow harm to infest our solutions.</li>
            <li><span className="strong">Simplifies:</span> Allow humans to address their problem, be helped, and move on. In the digital space, needless complexity is not only difficult, it can be dangerous and exclusionary. Digital experiences should be simple for all people. (I would argue there <span className="italic">are</span> some situations when you might deliberately incorporate friction and perhaps complexity, but intentionality is key.)</li>
        </ul>
        <p>My design philosophy is always evolving, but I believe that these three tenets provide a solid foundation for ensuring that digital design is on the right track and oriented in an ethical direction.</p>
    </article>
}

export default BeautifulUX;


