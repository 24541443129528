
import ProjectNavLink from "./projectNavLink";

const ProjectNav = (props) => {

  return <nav className="project-nav">
    <ul>
      {props.itemList.map((value, index) => {
        return <ProjectNavLink key={value} value={value} index={index} urlValue={props.urlList[index]}/>
      })}
    </ul>
  </nav>
}

export default ProjectNav;