import Section
 from "../section";
 import Survey from "../../img/AHFC/survey.png";
 import Forces from "../../img/AHFC/underlying-forces.png";
 import Persona from "../../img/AHFC/michelle.png";
 import Home from "../../img/AHFC/cozyhome.png";
 import MainFeatures from "../../img/AHFC/mainfeatures.png";
 import Cover from "../../img/AHFC/cover.png";
 import Purchase from "../../img/AHFC/purchase.png";
 import Community from "../../img/AHFC/community.png";

const AHFC = (props) => {
    const itchText = [
        "The African Heritage Food Co-op is a local food co-op in Buffalo, NY. Their goal is to empower their communities and resist systematic oppression by taking ownership of their food system and providing fresh, healthy food.",
        "Historically, government policies have resulted in a lack of investment in inner city communities, and as a result many people do not have access to healthy produce. The AHFC is much more than a food co-op: they are a resistance to oppression and discrimination. They needed an online store and website to promote and bring together their community around fresh food."
    ]
    const researchText = [
        "I conducted in-depth user research using multiple methods. I spent a lot of time going to AHFC food pickups and just talking to people, hearing their rants and complaints. I also sent out surveys and spoke with experts in the food systems field. I read essays about the issues and bounced what I was learning off of the founder of the co-op to make sure I was on the correct track. Ultimately, I came up with six key insights that drove the next design phases.",
        "1. People are drawn to the mission of the co-op; it resonates with people.",
        "2. Community is VERY important: most people are heavily involved with community.",
        "3. It is hard for people to afford quality and healthy produce/food.",
        "5. People want to know what produce they are going to get each month.",
        "6. People have trouble remembering to order and pick up their food.",
        "The key concept behind AHFC is ownership: it is a system built by the community for the community. The website will be designed with the same aim. Attracting customers and members in order to grow the AHFC community is a key goal, and this goal will be materialized through a digital space that brings that community together. The site will be a place where people are empowered to access healthy food, exchange ideas, contribute to their community, and above all, take control of their local system."
    ];
    const problemText = [
        "Economic oppression and institutionalized disinvestment in certain urban communities has led to a lack of access to fresh produce and healthy foods. The AHFC works to reverse these systems by giving ownership of food systems back to the communities through a co-op that provides fresh produce to the community.",
        "However, ordering food from the co-op is challenging and obscure, and there is no centralized way to share information, engage the community, and grow the co-op.",
        "Based off of this, we identified five key goals for the co-op:",
        "1. Build the community dedicated to taking ownership of AHFC.",
        "2. Increase the number of customers ordering produce boxes.",
        "3. Increase membership within the food co-op.",
        "4. Simplify the process of running the co-op.",
        "5. Increase the number of people volunteering for the co-op."
    ];
    const prototypeText = [
        "I developed the main features of the site based off of the goals developed with the community. I had some trouble designing a landing page that accommodated both frequent users who were active participants in the community and came to the site for specific reasons, and those new users who had yet to be pulled into the mission and see what AFHC was about.",
        "I decided to use someone’s home as an analogy. When you enter your home, you are comfortable there and you usually know right where to go. But you also want it to be welcoming and easy to navigate for guests, too. The website should replicate this concept to be comforting for community members and welcoming to newcomers.",
        "I used Invision software to prototype multiple iterations of wireframes and high-fidelity compositions and get feedback from the community. Finally we landed on a design that both resonated with the soul of the co-op and provided the functionality to enable it."
    ];
    const implementText = [
        "I built the site using WordPress with Square online ordering functionality. Throughtout the development process, I continued to turn back to the community, get their feedback, and make sure the site was still meeting their needs.", "I learned a lot through the development of this site. Originally, I was working with another developer, but he was inexperienced with custom theming and plugins in Wordpress, and ultimately wasn't able to help out with the majority of the site. I had never worked with Wordpress before, but I learned how to write custom themes and plugins and take advantage of hooks to build a site and e-commerce store that appeared completely custom for the end user yet leveraged Wordpress's powerful backend for admin maintenance. I also greatly improved my skills in PHP and database structure, as well as JavaScript.",
        "After development was complete, I developed a user guide, offered to train maintainers, and launched the site in a beta phase to find any bugs."
    ];
    const assessText = [
        "We hit challenges shortly after launch. Ultimately, I had built a site that was wonderful for the community- as a prototype. Unfortunately, live, they did not have the resources or skillsets to maintain it.",
        "As much as I had researched how to design the perfect site, I neglected to understand how implementation was just as important part of the design process. AHFC needed community, and functionality, but they also needed simplicity and low-maintenance. Their employees charged with updating the site did not have advanced computer skills, which I did not pay enough attention to during the research phase.",
        "Ultimately, the community went with a scaled-down design of the site that lacked many of the features but could be easily maintained."
    ];
    const refineText = [
        "In the future, I would include in my research from the get-go the capabilities that the community had and what the launch plan would look like. I would devote more time ideating on solutions that filled core needs while being easily supported.",
        "I recognize now that when designing solutions, it is more important to understand how they will integrate and influence the humans that use them than to design the perfect prototype."
    ];
    return <article className="center projectSection">
        <h3>African Heritage Food Co-op</h3>
        <p className="italic">A website and online store for a Black-owned co-op looking to take ownership of their food system.</p>
        <img src={Cover} alt="Website Cover" />
        <Section number="1" className="center" value="The Itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="center" value="research" text={researchText} hasQuote={false}/>
        <h4>Pre-existing User Engagement</h4>
        <img src={Survey} alt="Surey Results" />
        <h4>Underlying Forces that Drive AHFC</h4>
        <img src={Forces} alt="Underlying Forces" />
        <h4>User Persona</h4>
        <img src={Persona} alt="User Persona" />
        <Section number="3" className="center" value="problem" text={problemText} hasQuote={false}/>
        <Section number="4" className="center" value="prototype" text={prototypeText} hasQuote={false}/>
        <h4>Main Features</h4>
        <img src={MainFeatures} alt="Main Features" />
        <h4>Cozy Home Analogy</h4>
        <img src={Home} alt="A cozy home" />
        <div><a href="https://invis.io/V5LBP8LU8C9#/302356944_Landing" rel="noreferrer" target="_blank">View Wireframe Prototype</a></div>
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={false}/>
        <img src={Cover} alt="Website Cover" />
        <img src={Purchase} alt="Purchase" /> 
        <img src={Community} alt="Community" />
        <div className="aContainer"><a href="https://invis.io/V5LBP8LU8C9#/305638790_Chosen" rel="noreferrer" target="_blank">Final Composition Prototype</a></div>
        <div className="aContainer"><a href="https://invis.io/V5LBP8LU8C9#/302356944_Landing" rel="noreferrer" target="_blank">Final Mobile Composition Prototype</a></div>
        <Section number="6" className="center" value="assess impact" text={assessText} hasQuote={false}/>
        <Section number="7" className="center" value="continuous refinement" text={refineText} hasQuote={false}/>
  </article>
}

export default AHFC;