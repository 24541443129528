
const ISISTwitter = () => {
    return <article className="center">
        <h3>ISIS Twitter Network Exploration</h3>
        <div className="outerVideo">
        <div className="video">
            <iframe title="ISISTwitter" src="https://player.vimeo.com/video/198905222?h=dbf249ed61&title=0&byline=0&portrait=0" className="innerVideo" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <p>This was a project I completed towards the end of 2016, when ISIS was a major threat and spreading their ideology via Twitter. ISIS had some of the best recruiting tactics in the world, and a knack for inspiring people in wealthy countries via the internet, especially on Twitter. Twitter struggled to deal with a massive ISIS following and sought to understand whether suspending ISIS accounts helped reduce ISIS recruitment and activity on Twitter. In addition, there was (and remains today) an ethical debate surrounding attempts to stifle user "free speech" on Twitter.</p>
        <p>I researched this problem and designed a museum exhibit that would allow a user to walk through the process of identifying ISIS accounts, study them, and then analyze the effects of suspending them. The exhibit would have two screens: one to display the information and another that would allow the user to control where they are in the process and interact with the information.</p>
        <p>The goal was to demonstrate to museum goers the ethical dilemma of how improved technology has aided in terrorism, and to illustrate the different approaches of dealing with ISIS on Twitter; specifically the usefulness of suspending accounts. The exhibit would arm the user with the necessary information to draw their own conclusions about Isis, Twitter, and the ethics of terrorism and technology.</p>
        <p>While the threat of ISIS is dramatically reduced across the world, the central themes of this project remain prevalent in society today: censorship of speech on social media, spreading of radical ideas, and the ability of social media companies to address serious societal issues playing out on their platforms.</p>
    </article>
}

export default ISISTwitter;


