const SectionTitle = (props) => {
    let p_classname = "sectionTitle"
    return (
    <div className={p_classname}>
        <h1>{props.number}</h1>
        <h2>{props.value}</h2>
    </div>
    )
}

export default SectionTitle;