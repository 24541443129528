import Section
 from "../section";
 import Wiring from "../../img/Smiles/all-wiring.jpg"
 import Kiosk from "../../img/Smiles/kiosk.jpg"
 import ThinkSpeak from "../../img/Smiles/thinkspeak.jpg"
import ImageSection from "../imageSection";
import Mockup from "../../img/Smiles/mockup.png";
import InstallationMockup from "../../img/Smiles/installation_mockup.jpg";
import Processing from "../../img/Smiles/processing.gif";
import MultipleMotors from "../../img/Smiles/multiple-motors.jpg";
import Setup from "../../img/Smiles/setup2.jpg";
import SetupServeer from "../../img/Smiles/setupserver.jpg";
import Drop from "../../img/Smiles/drop.gif";

const SmilesPerHour = (props) => {
    const itchText = [
        "Mental health is critical for performance and health, yet depression and other mental health issues are rampant in college campuses across the US. A team of students and I wondered if we could study and bring awareness to the problem."
    ]
    const researchText = [
        "First, we completed background research to better understand the issues of mental health across college campuses.",
        "Second, we measured the mental health of students at our college campus, RIT. We decided the best way to do this would be to directly ask students about their happiness. This also helped open conversations about student emotional health. We built kiosks with five buttons on them. Each button had a face on it with varying levels of happiness, from sad to happy. We deployed three of these kiosks across RIT and collected student input on their own happiness.",
    ];
    const researchText2 = [
        "I took on the challenge of figuring out how to record data from buttons. We knew that we needed some type of microcontroller. I had never worked with microcontrollers, so we began with experimenting with Arduinos."
    ]
    const researchText3 =  [
        "The next step was to figure out how to get the input sent to an online database directly from the kiosks. Originally, I started working with a Raspberry Pi 3, which had built in WiFi. We used ThinkSpeak, a data analysis platform for the Internet of Things. I was able to use an API to send the data directly to ThinkSpeak, where we could quickly view, graph, parse, and download the data."
    ]
    const researchText4 = [
        "Raspberry Pis can be pricey, so we chose the cheaper, less user-friendly NodeMCUs. These were wifi enabled, but they were a bit more complex to program. I spent a couple days researching the Nodes and fighting with my code. Finally, I managed to get a test contraption set up with the Node and five small buttons.",
        "After that, it was just scaling up the solution into the kiosks themselves, and placing them around campus!"
    ]
    const problemText = [
        "We found that school work often gets in the way of life for many college students. A growing body of research shows that happiness plays a major role in productivity. It is vital to ensure that students are emotionally healthy in order to maximize productivity and success on college campuses."
    ];
    const prototypeText = [
        "We brainstormed the best way to use this information to bring awareness to mental health, and decided to raise awareness through an interesting and entertaining visual spectacle. We wanted to help students and commmunity members alike engage with the topic in a way that was fun and hopeful.",
       
    ];
    const prototypeText2 = [
        "We chose to display the happiness data as a line graph made up of physical balls hanging from the ceiling. The display would be controlled by a large touchscreen. The idea was that the touchscreen would show the entire window of time that we recorded data, and the user would be able to use sliders to adjust the time window that they wanted to view. The physical display would update to display a detailed view of that window of time.",
    ]
    const prototypeText3 = [
        "I had the role of writing the code that would interface Arduino and the motors with Processing (which controlled the graph). First, I mocked up the algorithm that would allow a slider to update a graph in this manner. I used Processing to do so, because it is an easy way to program quick visual sketches.",
        "The next step was to to get the stepper motors running with the motor drivers and the arduino. We chose stepper motors for their level of precision. I found a library that allowed me to easily program the steppers to step a certain amount of steps, and calculated how many steps I needed the motor to step in order to lower the ball a certain length. Next, I had to combine the running of the stepper motors with my algorithm. Luckily, it is very simple to interface Processing and Arduino.",
    ]
    const prototypeText4 = [
        "The code had to scale up to accomodate 13 Arduinos for a graph composed of 26 balls (each arduino could control two motors). This meant that Processing had to keep track of which serial port held which Arduino. We used a USB hub and paid particular attention to which port mapped to which Arduino so we could be sure the correct motor was receiving the correct number of steps to rotate."
    ]
    const prototypeText5 = [
        "Finally, it was time to put the whole thing together with all 13 motors, 25 balls, and 25 wheels to raise and lower the balls. My teammate, Tim, did most of the wire soldering and gluing motors to the planks. We used heavy paper to build the wheels that raised and lowered the balls (which were white christmas tree ornaments). These attached to the stepper motors. We wrapped fishing line around the wheels and attached the balls to the end."
    ]
    const prototypeText6 = [
        "Our development team built the large GUI for the touch screen. That meant I had to set up a client/server program so that their GUI could send the user's chosen time window to the Processing program, which ideally would be running on a laptop that was placed hidden in the ceiling next to the plank holding the motors.",
        "I set up a router and got the touch screen (which was hooked up to a desktop) communicating with my program. However, there was too much noise in the air, which caused an irritating delay. I ultimately ended up leaving the laptop on the ground and connecting it to the arduinos via long USB cords, and connecting the laptop to the desktop via ethernet cable."
    ]
    const prototypeText7 = [
        "And it worked!",
        "After a week of almost no sleep and a lot of debugging and troubleshooting, everything was up, running, and connected."
    ]
    const implementText = [
        "The installation was displayed at Imagine RIT. Students, parents, kids, and anyone else who came was able to use the touchscreen and watch the physical graph change to reflect student happiness during the different time intervals."
    ];
    const assessText = [
        "We learned . . . a lot from this implementation. While it was successful in bringing awareness of mental health on campus by using a showy display, it would have been more effective if we had more time to parse and analyze the data. We would have loved to enable the interaction to explore correlations between significant events and circumstances (finals, vacations, elections, weather, etc.) and mental health, as well as break down different demographics to understand where and how mental health was being impacted.",
        "Additionally, we didn't generate any metrics to know if we'd accomplished our goal bringing awareness to mental health. In the future, we would want to have a more specific plan for what to do with our data and recommendations to improve mental health on campus."
    ];
    const refineText = [
        "We had ideas that we could scale this project and expand it across other college campuses and other organizations. Were we to do this, we would have needed to pay close attention to the intricacies of mental health at those organizations, and possibly refined our data collection measures."
    ];
    return <article className="center projectSection">
        <h3>Smiles Per Hour</h3>
        <p className="italic">A project to bring awareness to mental health across college campuses.</p>
        <div className="outerVideo">
        <div className="video">
            <iframe title="coolvideo" src="https://player.vimeo.com/video/245839290?h=168320a772&title=0&byline=0&portrait=0" className="innerVideo" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <Section number="1" className="center" value="The Itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="center" value="research" text={researchText} hasQuote={false}/>
        <ImageSection text={researchText2} sourceImg={Wiring} altText={"Wiring Up Microcontrollers"} />
        <ImageSection text={researchText3} sourceImg={ThinkSpeak} altText={"ThinkSpeak"} title="Happiness Data on ThinkSpeak" />
        <ImageSection text={researchText4} sourceImg={Kiosk} altText={"Kiosk"} title="Finished Kiosks" />
        <div><a href="https://github.com/gilbertcasey2/ButtonCode/blob/master/button_code.ino" rel="noreferrer" target="_blank">View Kiosk Code on GitHub</a></div>
        <Section number="3" className="center" value="problem" text={problemText} hasQuote={false}/>
        <Section number="4" className="center" value="prototype" text={prototypeText} hasQuote={false}/>
        <ImageSection text={prototypeText2} sourceImg={Mockup} altText={"Kiosk"} />
        <img src={InstallationMockup} alt="3D mockup of the installation" />
        <ImageSection text={prototypeText3} sourceImg={Processing} altText={"Processing Prototype"} />
        <div className="marginBottom"><a href="https://github.com/gilbertcasey2/SmilesPerHour/tree/master" rel="noreferrer" target="_blank">View Code on GitHub</a></div>
        <ImageSection text={prototypeText4} sourceImg={MultipleMotors} altText={"Processing Prototype"} />
        <ImageSection text={prototypeText5} sourceImg={Setup} altText={"Setup"}/>
        <ImageSection text={prototypeText6} sourceImg={SetupServeer} altText={"Server Setup"}/>
        <ImageSection text={prototypeText7} sourceImg={Drop} altText={"It works!"}/>
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={false}/>
        <h4>Smiles Per Hour - Explained</h4>
        <div className="outerVideo">
        <div className="video">
            <iframe title="infoVideo" src="https://player.vimeo.com/video/245831458?h=f81f215702&title=0&byline=0&portrait=0" className="innerVideo" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <Section number="6" className="center" value="assess impact" text={assessText} hasQuote={false}/>
        <Section number="7" className="center" value="continuous refinement" text={refineText} hasQuote={false}/>
  </article>
}

export default SmilesPerHour;