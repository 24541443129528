import Section
 from "../section";
const ResearchProposal = (props) => {
    const itchText = ["Climate change is intensifying resource scarcity, exacerbating health risks, and inflaming inequality and instability.", 
    "Experts widely agree that climate change is an immediate threat and that there are certain policies, such as a carbon tax, that would be highly effective at reducing greenhouse gas emissions. However, communicating the urgency of climate change to the general population and coalescing around a solution is a huge challenge.",
    "One of the most effective ways to combat climate change is through government legislation, but how do we generate enough support for legislation to pass?"
    ]
    const researchText = [
        "People going about their day-to-day life leave bits of data, or \"digital breadcrumbs\", via credit card purchases, location data, or cell phone data. The expanding quantity and sophistication of these social sensors generate immense datasets that allow us a high-fidelity analysis of human behavior and enable us to study how information flows through society.",
        "We can therefore observe at a highly granular level how sentiment towards climate change flows through a population, study how and why misconceptions form, and understand how people think about and prioritize the crisis.",
    ];
    const problemText = [
        "At some point, understanding and idea flow regarding climate change is breaking down, and people either don't believe it is a problem, or they can't comprehend the criticality of the crisis. This manifests in a lack of concensus and political will directed towards solving the problem.",
        "How might we ethically and intentionally redesign social systems to facilitate the flow of accredited understanding and build the consensus needed to deliver effective climate legislation?",
    ];
    const prototypeText = [
        "We can use 'social mining', or discovering patterns in large data sets, to model human behavior and interactions. We can use these models to prototype ideas and potential solutions.",
        "We can test out potential adjustments to social systems to understand how we can design shifts in human interactions to facilitate understanding and concencus around climate action."

    ];
    const implementText = [
        "There are some ethical considerations with any potential implementations. By choosing to study and design for some populations, are we inadvertently neglecting or even harming others?",
        "We must deliberately consider two questions: Which impacts of climate change are we prioritizing awareness of? What solution are we driving towards, and who specifically does it (not) help?"
    ];
    const assessText = [
        "Due to ethical considerations, we must be thorough and careful with our assessment of any implemented solution. There are three main measures of success for a potential solution:",
        "1. Quality of thought and accuracy of understanding regarding climate change",
        "2. Unity of thought across the population",
        "3. Projected likelihood for success of the concencus to equitably combat climate crisis"
    ];
    const refineText = [
        "Climate change is a rapidly evolving crisis. We must continue to reassess our solution as conditions change.",
    ];
    return <article className="center projectSection">
        <h3>Research Proposal</h3>
        <p className="italic">A plan to use design, systems thinking, and social physics to research how we might build concencus across society to solve climate change.</p>
        <Section number="1" className="center" value="The Itch" text={itchText} hasQuote={false}/>
        <Section number="2" className="left" value="research" text={researchText} hasQuote={false}/>
        <Section number="3" className="center" value="problem" text={problemText} hasQuote={false}/>
        <Section number="4" className="left" value="prototype" text={prototypeText} hasQuote={false}/>
        <Section number="5" className="center" value="implement" text={implementText} hasQuote={false}/>
        <Section number="6" className="left" value="assess impact" text={assessText} hasQuote={false}/>
        <Section number="7" className="center" value="continuous refinement" text={refineText} hasQuote={false}/>
  </article>
}

export default ResearchProposal;