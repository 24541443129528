import PageTitle from "./pageTitle";
import Diamond from "./diamond";
import Selfie from "../img/me-professional-small.png"

const MyStory = (props) => {

    return <section className="myStory bigSection center">
        <div className="diamondContainer">
        <Diamond className="one"/>
        <Diamond className="two"/>
        <Diamond className="three"/>
        </div>
        <PageTitle value="my story" />
        <div class="selfiecontainer"><img className="selfie" src={Selfie} alt="Website Cover" /></div>
        <div class="myStoryContent">
        <h3>I am a problem solver trying to make the world a better place for humans, plants, and animals.</h3>
        <p>I apply design thinking to solve social problems. I believe not only that design can address social issues, but that design thinking can be joined with systems thinking to redesign social systems. We can use data to research and understand where our social systems fail us, divergent thinking to brainstorm solutions, and computational models to prototype those solutions. Finally, we will monitor, assess, and refine, because the design process never truly ends.</p>
        <h4>the beginning . . .</h4>
        <p>I started this journey at age 19, when a series of strange events culminated in a depressing job doing door-to-door internet sales far from my home, wondering why society pushed people into poverty and pointless labor. I went back to school at Rochester Institute of Technology, where I studied design thinking as a methodology to solve problems. I began to wonder: could we increase the reach of design to solve larger, thornier social issues? At school, I chose projects that focused on social issues, such as <a  href="/projects/smiles" target="_blank" className="storyLink">mental health</a>, <a  href="/other/isis-twitter-network" target="_blank" className="storyLink">extremism</a>, and <a  href="/other/strangled-egg#strangeledEgg" target="_blank" className="storyLink">sustainability</a>. I also began to theorize about how we could apply design thinking to systems: could we apply design not only to single failure points, but redesign the systems themselves?</p>
        <h4>progress . . .</h4>
        <p>I have been using design to address social issues ever since. After graduating, I designed <a  href="https://blastgauge.com/tutorials/" target="_blank" className="storyLink" rel="noreferrer">tutorials</a> and  <a  href="/other/blast-guage-system" target="_blank" className="storyLink">other materials</a> for Black Box Biometrics, a company developing sensors to detect Soldiers’ exposure to explosions. In 2018, I designed and developed an <a  href="/projects/ahfc" target="_blank" className="storyLink">e-commerce site for a food co-op</a> addressing food apartheid in Buffalo NY, and worked on another project <a  href="/projects/myability" target="_blank" className="storyLink">researching challenges with employment</a> for individuals with disabilities. In 2019 I began working for Epic Systems, a Healthcare IT company, where I focused on executing design research as well as building tools and implementing company processes for research in order to improve healthcare systems and make them safer for patients and providers alike.</p>
        <h4>looking forward . . .</h4>
        <p>However, it seems to me that the greatest existential threat facing our society is climate change. Poverty, violence, and extremism will only grow worse if we do not address climate change. With this in mind, I co-launched a group at Epic focused on helping healthcare organizations reduce their carbon footprint. In my free time, I volunteer with Citizens’ Climate Lobby (CCL), an organization devoted to passing government legislation to combat climate change. I designed and developed a <a  href="https://cclwi.org" target="_blank" className="storyLink" rel="noreferrer">website for Wisconsin CCL</a> to help grow the chapters and make information easily accessible.</p>
        <p>But I believe design has a broader potential to fix social systems. Currently, I am exploring how we might redesign our systems to <a  href="/projects/research" target="_blank" className="storyLink">bring people together around a shared understanding of climate change</a> and climate action. Design can guide us into working together, and together we can fight climate change.</p>
        </div>
    </section>
}

export default MyStory;