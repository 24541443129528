import SectionTitle from "./sectiontitle";

const Section = (props) => {
    let p_className = "section " + props.className;
    let bodyText = [];
    let italicClass = "";
    props.hasQuote && (italicClass = "italic");
    for(var i = 0; i < (props.text).length; i++) {
        bodyText[i] = (props.text)[i];
    }
    const renderList = bodyText.map((item, index) => 
        <p className={(index === 0) ? italicClass : ""} key={index}>{item}</p>
    );
    return (
    <section className={p_className}>
        <SectionTitle number={props.number} value={props.value} />
        {renderList}
    </section>
    )
}

export default Section;